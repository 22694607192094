import { ParsedUrlQuery } from 'querystring';

const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;

function removeBaseUrl(url: string) {
  return url.replace(baseUrl!, '');
}

function addBaseUrl(url: string) {
  return `${baseUrl!}${url}`;
}

function setQuery() {
  /* let input: HTMLInputElement;
  let query: string = 'Werkbank';
  if (window !== undefined) {
    input = document.querySelector('input .search-form__input') as HTMLInputElement;
    query = input.value;
  } */
  return 'Test'; // encodeURIComponent(query);
}

function callSearch() {
  const input = document.querySelector('.search-form__input') as HTMLInputElement;
  const query: string = input.value;
  window.open(addBaseUrl(`/artikel/suche/${encodeURIComponent(query)}`), '_blank');
}

function toQuerystring(query: ParsedUrlQuery) {
  return Object.entries(query)
    .filter(Boolean)
    .map(entry => entry.join('='))
    .join('&');
}

export { addBaseUrl, removeBaseUrl, toQuerystring, setQuery, callSearch };
