import { removeBaseUrl } from '@/utils/url';
import Link from 'next/link';

type FooterMenuData = {
  id: number;
  title: string;
  link_intern_full_url: string | null;
  link_url: string | null;
};

type FooterData = {
  id: number;
  footer_menu: FooterMenuData[];
};

type Props = {
  data: FooterData | null;
};

function Footer(props: Props) {
  const { data } = props;

  const current = new Date();
  const date = `${current.getFullYear()}`;

  return (
    <footer>
      {data?.footer_menu.length ? (
        <ul className='footer__menu-list'>
          {data.footer_menu.map(entry => (
            <li key={entry.id} className='footer__menu-list-item'>
              <Link
                href={
                  entry.link_intern_full_url
                    ? removeBaseUrl(entry.link_intern_full_url)
                    : entry.link_url ?? '/'
                }
                passHref
                legacyBehavior
              >
                {entry.title}
              </Link>
            </li>
          ))}
        </ul>
      ) : null}
      <ul className='footer__menu'>
        <li>
          <Link passHref href='https://werkbank.de' className='footer__menu__item'>
            Werkbank
          </Link>
        </li>
        <li>
          <Link passHref href='https://werkbank.de/jobs' className='footer__menu__item'>
            Jobs
          </Link>
        </li>
        <li>
          <Link passHref href='https://werkbank.de/datenschutz' className='footer__menu__item'>
            Datenschutz
          </Link>
        </li>
        <li>
          <Link passHref href='https://werkbank.de/impressum' className='footer__menu__item'>
            Impressum
          </Link>
        </li>
      </ul>

      <p className='copyright'>©{date} werkbank GmbH</p>
    </footer>
  );
}

export default Footer;
