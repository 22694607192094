import { toggleDarkMode } from '@/utils/colormode';
import Image from 'next/image';
import Link from 'next/link';
import logo from '../../styles/assets/images/werkbank_logo_rund_black.png';


function Header() {
  return (
    <header className='header'>
      <Link href="/" title="Startseite" className='logo'>
        <Image
          src={logo}
          alt="Werkbank Logo"
          width={68}
          height={68}
        // blurDataURL="data:..." automatically provided
        // placeholder="blur" // Optional blur-up while loading
        />

      </Link>







      <Link href="https://werkbank.de" className="type_logo">Werkbank</Link>

      <button className='toggle-dm-button' aria-label='Toggle Dark Mode' onClick={toggleDarkMode} />

    </header>
  );
}

export default Header;
