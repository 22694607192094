function saveColorPreference(colorMode: string) {
  document.cookie = `colorpref=${colorMode}; Path=/; SameSite=None; Secure`;
}

function readColorCookie() {
  let cookieValue: string | undefined;
  if (document.cookie.split(';').some(item => item.trim().startsWith('colorpref='))) {
    cookieValue = document.cookie
      .split('; ')
      .find(row => row.startsWith('colorpref='))
      ?.split('=')[1];
  }

  return cookieValue;
}

function toggleDarkMode() {
  document.body.classList.toggle('dark-theme');
  const currentPreference = document.body.classList.contains('dark-theme') ? 'dark' : 'light';
  saveColorPreference(currentPreference);
}

function colorModeInit() {
  const preference = readColorCookie();

  if (preference === undefined) {
    if (
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches &&
      !document.body.classList.contains('dark-theme')
    ) {
      toggleDarkMode();
    } else {
      saveColorPreference('light');
    }
  } else if (preference === 'dark' && !document.body.classList.contains('dark-theme'))
    toggleDarkMode();
  window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
    const newColorScheme = event.matches ? 'dark' : 'light';
    if (newColorScheme !== readColorCookie()) {
      toggleDarkMode();
    }
  });
}

export { toggleDarkMode, colorModeInit, readColorCookie };
